<template>
  <v-container fluid>
    <!-- Tarjeta de certificado de membresía -->
    <v-row class="text-center ma-3" justify="center">
      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 7" class="pa-0">
        <v-card
          elevation="2"
          class="d-flex flex-column"
          height="100%"
          :rounded="$vuetify.breakpoint.mdAndDown ? 't-xl b-0' : 'l-xl r-0'"
        >
          <v-img
            position="center"
            class="ma-0 d-flex"
            content-class="filter-red"
            src="@/assets/images/landing/memberLanding/background.png"
          >
            <v-card
              height="100%"
              color="transparent"
              :class="
                $vuetify.breakpoint.mdAndDown
                  ? 'd-flex flex-column justify-center'
                  : 'd-flex flex-column pa-5'
              "
            >
              <v-card-title
                :class="
                  $vuetify.breakpoint.mdAndDown
                    ? 'justify-center'
                    : 'justify-left'
                "
                class="wDarkerBlue--text ma-0"
              >
                <p
                  class="ma-0"
                  :style="
                    $vuetify.breakpoint.mdAndDown
                      ? 'font-size:15px; word-break: break-word'
                      : 'font-size:17px  word-break: break-word'
                  "
                >
                  CREDENCIAL VIRTUAL
                </p>
                <!-- Botón de descarga -->
                <!-- <v-btn class="ml-3 brown lighten-5" fab small :elevation="0">
                  <v-icon>mdi-download-outline</v-icon>
                </v-btn> -->
              </v-card-title>
              <v-card-text
                class="wDarkerBlue--text mt-5"
                :style="
                  $vuetify.breakpoint.smAndDown
                    ? 'font-size:17px'
                    : 'font-size:15px'
                "
              >
                <v-row
                  class="pb-4 mx-0 h-100"
                  align="start"
                  justify="space-between"
                >
                  <v-avatar color="#1c2434" size="120">
                    <v-img
                      v-if="
                        user.profilePicture &&
                        user.profilePicture != 'NOT_ASSIGNED'
                      "
                      :src="user.profilePicture"
                    ></v-img>
                    <v-img
                      v-else
                      src="https://media.istockphoto.com/id/1337144146/vector/default-avatar-profile-icon-vector.jpg?b=1&s=170667a&w=0&k=20&c=-qQGlKM8OQsSJCEkHnqS9FI94VRTkZ-7tg0K0u02XL0="
                    ></v-img>
                  </v-avatar>
                  <div class="d-flex flex-column align-center">
                    <v-btn
                      class="btnstyle subtitle-2 font-weight-black pa-6 d-flex justify-center primary-variant"
                      :loading="load"
                      :color="homologateStatusWithColor(status).color"
                    >
                      {{ homologateStatusWithColor(status).status }}
                    </v-btn>

                    <div
                      class="my-2 mx-4 text-right body-2"
                      v-if="
                        user.headOffice.id === '62b622c66a7441ae20159d7d' ||
                        user.headOffice.id === '6297b0fec7bf8b6f826313be'
                      "
                    >
                      <span v-if="stars > 0">
                        <span v-for="i in stars" :key="i">
                          <i
                            class="mdi mdi-star mdi-35px"
                            style="color: orange"
                          ></i>
                        </span>
                      </span>
                      <span v-if="stars < 5">
                        <span v-for="i in 5 - stars" :key="i">
                          <i
                            class="mdi mdi-star-outline mdi-35px"
                            style="color: orange"
                          ></i>
                        </span>
                      </span>
                    </div>
                  </div>
                </v-row>
                <v-row>
                  <div class="my-2 mx-4 text-left body-2">
                    <span
                      class="primary-variant subtitle-2 font-weight-black mayus"
                    >
                      Nombres
                    </span>
                    - {{ user.name }}
                  </div>
                  <div class="my-2 mx-4 text-left body-2">
                    <span
                      class="primary-variant subtitle-2 font-weight-black mayus"
                    >
                      Apellidos
                    </span>
                    - {{ user.firstLastname }}
                  </div>
                  <div
                    v-if="user.hasOwnProperty('memberOf')"
                    class="my-2 mx-4 text-left body-2"
                  >
                    <span
                      class="primary-variant subtitle-2 font-weight-black mayus"
                    >
                      Nivel de membresía
                    </span>
                    - {{ user.memberOf.affiliationType }}
                  </div>
                  <div
                    v-for="(field, i) in credentialData"
                    :key="i"
                    class="my-2 mx-4 text-left body-2"
                    :class="showField(field) ? '' : 'd-none'"
                  >
                    <div v-if="field.fieldType != 'DOC'">
                      <span
                        class="primary-variant subtitle-2 font-weight-black mayus"
                      >
                        {{ field.name }}
                      </span>
                      - {{ field.value || "N/D" }}
                    </div>
                  </div>
                </v-row>
              </v-card-text>

              <v-card-actions
                :class="
                  $vuetify.breakpoint.mdAndDown
                    ? 'justify-center'
                    : 'justify-end'
                "
                class="d-flex"
              >
              </v-card-actions>
            </v-card>
          </v-img>
        </v-card>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 4" class="pa-0">
        <v-card
          height="100%"
          elevation="2"
          class="pt2 d-flex justify-center align-center pa-4"
          :rounded="$vuetify.breakpoint.mdAndDown ? 'b-xl t-0' : 'r-xl l-0'"
        >
          <v-img
            :max-height="$vuetify.breakpoint.smAndDown ? 160 : 300"
            :max-width="$vuetify.breakpoint.smAndDown ? 160 : 300"
            class="rounded-0"
            :src="qrLink"
            content-class="filter-red"
          />

          <!--Overlay for expired-->
          <v-overlay
            absolute
            :value="true"
            :color="status === 'EXPIRED' ? 'grey lighten-4' : 'red lighten-4'"
            opacity="0.95"
            v-if="status === 'EXPIRED' || status === 'INACTIVO'"
          >
            <v-row justify="center">
              <v-icon color="black"> mdi-message-alert-outline </v-icon>
            </v-row>
            <v-row class="my-4" justify="center">
              <v-col cols="9" class="text-start">
                <span
                  class="black--text subtitle-1 font-weight-black"
                  v-if="status === 'EXPIRED'"
                >
                  Tú membresía ha expirado
                </span>
                <span
                  class="black--text subtitle-1 font-weight-black"
                  v-if="status === 'INACTIVO'"
                >
                  Tú membresía está inactiva
                </span>
                <br />
                <span class="black--text subtitle-1">
                  {{
                    user.role == "MEMBER"
                      ? "Renueva tu membresía para seguir disfrutando de los beneficios"
                      : "Por favor comunicate con el propietario de la cuenta principal."
                  }}
                </span>
              </v-col>
            </v-row>
          </v-overlay>

          <!--Overlay for prospecto-->
          <v-overlay
            absolute
            :value="true"
            color="yellow lighten-4"
            opacity="0.95"
            v-if="status === 'PROSPECTO'"
          >
            <v-row justify="center">
              <v-icon color="#1B273E"> mdi-message-alert-outline </v-icon>
            </v-row>
            <v-row class="my-4" justify="center">
              <v-col cols="9" class="text-start">
                <span
                  class="primary-variant subtitle-1 font-weight-black"
                  v-if="nationalIsPaid"
                >
                  Perfecto, solo nos falta el pago local
                </span>
                <span
                  class="primary-variant subtitle-1 font-weight-black"
                  v-else
                >
                  1.
                  <span class="font-weight-regular">
                    Primero debes cubrir tu
                  </span>
                  pago nacional <br />
                  2.
                  <span class="font-weight-regular">
                    Una vez confirmado tu pago, podrás continuar con el
                  </span>
                  pago local <br />
                  3.
                  <span class="font-weight-regular">
                    Una vez cubiertos ambos pagos, se habilitará tu tablero.
                  </span>
                </span>
                <br />
                <span class="primary-variant subtitle-1">
                  {{
                    user.role == "MEMBER"
                      ? nationalIsPaid
                        ? "Continua con el pago para disfrutar de todos los beneficios."
                        : ""
                      : "Tienes pagos pendientes, por favor comunicate con el propietario de la cuenta principal."
                  }}
                </span>
                <v-row justify="end" class="pt-8" v-if="user.role == 'MEMBER'">
                  <v-btn
                    v-if="nationalIsPaid"
                    color="#1B273E"
                    class="caption"
                    rounded
                    :to="`/member/payment/${user.affiliation.local._id}`"
                  >
                    Iniciar pago
                    <v-icon small class="ml-2">mdi-arrow-expand-right</v-icon>
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-overlay>
        </v-card>
      </v-col>
    </v-row>

    <v-row
      justify="center"
      v-if="isOnIndulgencePeriod && user.role == 'MEMBER'"
    >
      <v-col cols="11">
        <v-row class="mx-0" justify="end" align="center">
          <v-icon class="mr-1" color="#1B273E">
            mdi-message-alert-outline
          </v-icon>
          <span style="color: #1b273e" class="body-2 font-weight-black">
            {{
              status == "EXPIRED"
                ? "Tu membresía ha expirado"
                : status == "INACTIVO"
                ? "Tu membresía está inactiva"
                : ableToRenewNational
                ? "Tu membresía está próxima a vencer"
                : ""
            }}
          </span>
          <v-btn
            color="#1B273E"
            class="mx-2 white--text font-weight-bold"
            rounded
            :to="getRenewLink()"
          >
            Renovar Ahora
            <v-icon small class="ml-2">mdi-arrow-expand-right</v-icon>
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import {
  homologateStatusWithColor,
  isActivePaymentNationalH,
  isActivePaymentLocalH,
  ableToRenewH,
} from "@/common/hook/useHelper.js";
import { set } from "vue";

export default {
  data() {
    return {
      stars: 0,
      isActivePaymentNational: false,
      isActivePaymentLocal: false,
      ableToRenewNational: false,
      ableToRenewLocal: false,
      affiliation: {},
      fields: [],
      value: "",
      id: "",
      ppicture: "",
      load: false,
      profile: "",
      cred: {},
      items: [
        {
          title: "asociado ",
          content: "",
        },
        { title: "titular ", content: "" },
        { title: "domicilio ", content: "" },
        { title: "capitulo ", content: "" },
        { title: "Municipio ", content: "" },
        { title: "Tipo de membresía: ", content: "" },
      ],
      paisId: "63f119deddcf3d001b703692",
      ampiId: "6297b0fec7bf8b6f826313be",
    };
  },
  methods: {
    ...mapActions("member", ["fetchMemberCredentials"]),
    ...mapActions("membershipUsers", ["fetchMembershipCredentials"]),
    ...mapMutations("loader", ["loading", "loaded"]),
    ...mapActions("user", ["realoadUserData"]),
    ...mapActions("registrationFields", ["getRegistrationFields"]),
    homologateStatusWithColor,
    isActivePaymentNationalH,
    isActivePaymentLocalH,
    ableToRenewH,
    showField(field) {
      if (field.showInCredential != undefined) {
        if (field.fieldType == "DOC") {
          return false;
        } else {
          return field.showInCredential;
        }
      } else {
        return false;
      }
    },
    qrLinkId(id) {
      return `${window.location.origin}/member/credential/${id}`;
    },
    getRenewLink() {
      let paymentType = this.user.headOffice.paymentType;
      let link = `/member/renew/2/${this.user._id}`;
      let branch = this.userMembership
        ? this.userMembership.organization._id
        : this.user.branch;
      return `${link}${
        !["N/D", "N/A", null].includes(branch) && paymentType != "NATIONAL"
          ? "/" + branch
          : ""
      }`;
    },
    updateMemberCredential(credential) {
      this.id = `https://api.qrserver.com/v1/create-qr-code/?data=${this.qrLinkId(
        credential.id
      )}&bgcolor=deeae0`;
      this.items[0].content = credential.associate || "N/D";
      this.items[1].content = credential.name;
      this.items[2].content = credential.address;
      this.items[3].content = credential.branch;
      this.items[4].content = credential.city || "";
      let membershipType = credential.externalId
        ? credential.externalId.substring(0, 2)
        : this.user.role
        ? this.user.role
        : "";
      if (this.user.memberInformation.type) {
        this.items[5].content = this.user.memberInformation.type;
      } else {
        if (membershipType == "AS" || membershipType == "MEMBER") {
          this.items[5].content = "Asociado";
        } else if (
          membershipType == "AF" ||
          membershipType == "MEMBERSHIP_USER"
        ) {
          this.items[5].content = "Afiliado";
        } else if (membershipType == "IN") {
          this.items[5].content = "Internacional";
        } else {
          this.items[5].content = "Prospecto";
        }
      }
      this.ppicture =
        this.user.role == "MEMBER"
          ? credential.branchProfilePicture
          : credential.profilePicture;
      this.profile =
        credential.name.split(" ")[0].split("")[0] +
        credential.name.split(" ")[2].split("")[0];
    },
    async initializeFields() {
      //get list of fields
      const fields = await this.getRegistrationFields(this.user.headOffice._id);

      this.fields = fields.data.data;
      const member = fields.data.data;
      if (this.fields[1] && this.fields[1].value !== undefined) {
        this.value = this.fields[1].value;
      }
      //

      //this.setStars(member)
      //console.log(this.fields)
    },

    async setStars(member) {
      //console.log("MEMBER")
      //console.log("Miembro:", member);
      this.stars = 0;

      if (
        this.user.name != "" &&
        this.user.firstLastname != "" &&
        this.user.email != "" &&
        this.user.phone != undefined &&
        //member.memberInformation.codigo_postal != "N/D" &&
        member[0].value != "" &&
        //member.memberInformation.calle != "N/D" &&
        member[1].value != "" &&
        //member.memberInformation.num_int != "N/D" &&
        member[3].value != "" &&
        //member.memberInformation.colonia != "N/D" &&
        member[4].value != "" &&
        //member.memberInformation.municipio != "N/D" &&
        member[5].value != "" &&
        //member.memberInformation.estado != "N/D"
        member[6].value != ""
      ) {
        this.stars = this.stars + 1;

        if (
          //member.memberInformation.fecha_de_cumpleanos != "N/D" &&
          member[9].value != "" &&
          //member.memberInformation.grado_maximo_de_estudios != "N/D" &&
          member[11].value != "" &&
          //member.memberInformation.gender != "N/D" &&
          member[10].value != "" &&
          //member.memberInformation.comprobanteDomicilio != "NOT_ASSIGNED"
          member[15].value != "" &&
          member[15].value != null
        ) {
          this.stars = this.stars + 1;

          if (
            //member.memberInformation.curp != "N/D" &&
            member[7].value != "" &&
            // member.memberInformation.rfc != "N/D" &&
            member[8].value != "" &&
            //member.memberInformation.constanciaSituacionFiscal != "NOT_ASSIGNED" &&
            member[13].value != "" &&
            member[13].value != null &&
            //membermemberInformation.ine != "NOT_ASSIGNED"
            member[14].value != "" &&
            member[14].value != null
          ) {
            this.stars = this.stars + 1;

            if (
              //member.memberInformation.pagina_web != "N/D" //&&
              //member.memberInformation.companyImage != "N/D"

              member[16].value != "" &&
              member[16].value != null
            ) {
              this.stars = this.stars + 1;

              if (
                //member.memberInformation.pagina_web != "N/D"
                member[19].value != "" &&
                member[19].value != null
              ) {
                this.stars = this.stars + 1;
              }
            }
          }
        }
      }
    
    },
  },

  computed: {
    ...mapState("member", ["credential"]),
    ...mapState("user", ["user"]),
    ...mapState("membershipProfile", ["userMembership"]),
    isOnIndulgencePeriod() {
      let nationalInactivationDate = this.user.affiliation.national
        ? this.user.affiliation.national.details
          ? this.user.affiliation.national.details[0].affiliateInformation
            ? this.user.affiliation.national.details[0].affiliateInformation
                .inactiveDate
            : null
          : null
        : null;

      let localInactivationDate = this.user.affiliation.local
        ? this.user.affiliation.local.details
          ? this.user.affiliation.local.details[0].affiliateInformation
            ? this.user.affiliation.local.details[0].affiliateInformation
                .inactiveDate
            : null
          : null
        : null;

      let nationalIndulgence = true;
      let localIndulgence = true;

      if (nationalInactivationDate) {
        nationalIndulgence = new Date(nationalInactivationDate) < new Date();
      }

      if (localInactivationDate) {
        localIndulgence = new Date(localInactivationDate) < new Date();
      }

      if(this.user.headOffice.paymentType == "NATIONAL"){
        return nationalIndulgence;
      }else{
        if(this.user.headOffice.paymentType == "LOCAL"){
          return localIndulgence;
        }else{
          return nationalIndulgence || localIndulgence;
        }
      }
    },
    credentialData() {
      //set member info
      if (this.user.memberData) {
        for (let i = 0; i < this.fields.length; i++) {
          for (let j = 0; j < this.user.memberData.length; j++) {
            if (
              this.fields[i].formattedName ==
              this.user.memberData[j].formattedName
            ) {
              this.fields[i].value = this.user.memberData[j].value;
            }
          }
        }
      }

      //console.log("AYUDA")
      this.setStars(this.fields);
      return this.fields;
    },

    qrLink() {
      return `https://api.qrserver.com/v1/create-qr-code/?data=${this.qrLinkId(
        this.user._id
      )}&bgcolor=deeae0`;
    },
    filtItems() {
      //devuelve los items pero si el valor es "" devuelve ND
      return this.items.map((item) => {
        if (item.content == "") {
          item.content = "ND";
        }
        return item;
      });
    },
    nationalIsPaid() {
      return (
        this.user.affiliation.national &&
        this.user.affiliation.national.details &&
        this.user.affiliation.national.details.status === "ACTIVE"
      );
    },
    status() {
      return this.user.validationStatus;
    },
  },
  watch: {
    credential(newCredential) {
      this.updateMemberCredential(newCredential.credentialInformation);
    },
    user(val) {
      this.affiliation = this.user.affiliation;
    },
    affiliation(val) {
      if (val) {
        this.isActivePaymentNational = this.isActivePaymentNationalH(val);
        this.isActivePaymentLocal = this.isActivePaymentLocalH(val);

        if (val.national) {
          if (val.national.details) {
            let status = val.national.details[0].status;
            let affiliateInformation =
              val.national.details[0].affiliateInformation;

            this.ableToRenewNational = this.ableToRenewH(
              affiliateInformation,
              status
            );
          } else {
            this.ableToRenewNational = true;
          }
          // if (this.userMembership.status == "ACTIVE") {
          //   this.ableToRenewNational = false;
          // }
        }
        if (val.local) {
          if (val.local.details) {
            let status = val.local.details[0].status;
            let affiliateInformation =
              val.local.details[0].affiliateInformation;

            this.ableToRenewLocal = this.ableToRenewH(
              affiliateInformation,
              status
            );
          } else {
            this.ableToRenewLocal = true;
          }
        }
      }
    },
  },
  async mounted() {
    this.loading();
    await this.initializeFields();
    this.affiliation = this.user.affiliation;
    this.load = true;
    if (!this.credential.credentialInformation) {
      if (this.user.role === "MEMBER") {
        this.cred = (await this.fetchMemberCredentials()).credential;
      } else {
        this.cred = (await this.fetchMembershipCredentials()).credential;
      }
    } else {
      this.cred = this.credential.credentialInformation;
    }
    try {
      this.updateMemberCredential(this.cred);
    } catch (error) {
      console.log(error);
    } finally {
      this.loaded();
      this.load = false;
    }
  },
};
</script>

<style scoped>
.v-card__title {
  font-family: "Montserrat-Black", Fallback, sans-serif;
  font-weight: 800;
  font-size: 17px;
}

.pt2 {
  background-color: #deeae0;
}

.btnstyle {
  border-radius: 15px;
  font-weight: 900;
}

.mayus {
  text-transform: uppercase;
}

.head6 {
  font-family: Montserrat Bold;
}
</style>
